import React, { useEffect } from 'react';
import {
  Box,
  // Box,
  Flex,
  // Grid, GridItem, Text
} from '@chakra-ui/layout';
import { Modal, ModalBody, ModalContent } from '@chakra-ui/react';
// import { MailChimpEmailSubscribe } from './MailChimpEmailSubscribe';
import { lightsOn, PrizeType } from './utils';

import grandWinnerImage from './images/grand-winner.png';
import megaWinnerImage from './images/mega-winner.png';
import ultimateWinnerImage from './images/ultimate-winner.png';

interface StartModalProps {
  isOpen: boolean;
  onClose: () => void;
  prizeResult: PrizeType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  playFireworks: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stopFireworks: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  playCoinsFall: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const WinnerModal = ({
  isOpen,
  onClose,
  containerRef,
  prizeResult,
  playFireworks,
  stopFireworks,
  playCoinsFall,
}: StartModalProps) => {
  const [showGrandFireworks, setShowGrandFireworks] = React.useState(false);
  useEffect(() => {
    if (prizeResult === PrizeType.GRAND && isOpen) {
      playCoinsFall();
      lightsOn(true);
      setTimeout(() => {
        setShowGrandFireworks(true);
        playFireworks();
      }, 4200);
    }
    return () => {
      setShowGrandFireworks(false);
      stopFireworks();
      lightsOn(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, prizeResult]);
  return (
    <Modal
      onClose={onClose}
      size="full"
      isCentered
      isOpen={isOpen}
      portalProps={{ appendToParentPortal: true, containerRef }}
    >
      <ModalContent h="100%" maxH="100%" maxW="100%" background="none">
        <ModalBody
          background="rgba(0, 0, 255, 0)"
          h="100%"
          p={0}
          position="relative"
        >
          {prizeResult === PrizeType.GRAND && (
            <video
              // ref={videoRef}
              src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/coins-grand-win.mov"
              autoPlay
              // loop
              playsInline
              muted
              style={{
                zIndex: 100,
                objectFit: 'cover',
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
              }}
            />
          )}
          {prizeResult === PrizeType.GRAND && showGrandFireworks && (
            <>
              <video
                // ref={videoRef}
                src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/gold-fireworks.mov"
                autoPlay
                loop
                playsInline
                muted
                style={{
                  zIndex: 20,
                  objectFit: 'cover',
                  width: '100vw',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  opacity: 0.8,
                  // background: 'rgba(150, 0, 0, 0.9)',
                  // mixBlendMode: 'plus-lighter',
                }}
              />
              <video
                // ref={videoRef}
                src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/sunburst.mov"
                autoPlay
                loop
                playsInline
                muted
                style={{
                  zIndex: 30,
                  objectFit: 'cover',
                  width: '100vw',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  // opacity: 0.8,
                  // background: 'rgba(150, 0, 0, 0.9)',
                  mixBlendMode: 'plus-lighter',
                }}
              />
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                h="full"
                zIndex={31}
                position="relative"
              >
                <img src={grandWinnerImage} alt="" />
              </Flex>
            </>
          )}
          {prizeResult === PrizeType.MEGA && (
            <Box h="full" w="full" backgroundColor="rgba(4, 32, 85, 1)">
              <video
                // ref={videoRef}
                src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/sunburst.mov"
                autoPlay
                loop
                playsInline
                muted
                style={{
                  zIndex: 32,
                  objectFit: 'cover',
                  width: '100vw',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  // opacity: 0.8,
                  // background: 'rgba(150, 0, 0, 0.9)',
                  mixBlendMode: 'color-dodge',
                }}
              />
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                h="full"
                zIndex={33}
                position="relative"
                // backgroundColor="rgba(4, 32, 85, 1)"
              >
                <img src={megaWinnerImage} alt="" />
              </Flex>
            </Box>
          )}
          {prizeResult === PrizeType.ULTIMATE && (
            <Box h="full" w="full" backgroundColor="rgba(4, 50, 11, 1)">
              <video
                // ref={videoRef}
                src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/sunburst.mov"
                autoPlay
                loop
                playsInline
                muted
                style={{
                  zIndex: 32,
                  objectFit: 'cover',
                  width: '100vw',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  // opacity: 0.8,
                  // background: 'rgba(150, 0, 0, 0.9)',
                  mixBlendMode: 'color-dodge',
                }}
              />
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                h="full"
                zIndex={33}
                position="relative"
                // backgroundColor="rgba(4, 50, 11, 1)"
              >
                <img src={ultimateWinnerImage} alt="" />
              </Flex>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WinnerModal;
