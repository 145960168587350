/* eslint-disable */
import React from 'react';
import { Flex, Grid, GridItem } from '@chakra-ui/layout';
import MainTop from './MainTop';
import InfoBar from './InfoBar';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import ResultsModal from './ResultsModal';
import StartModal from './StartModal';
import ReelsV5 from './ReelsV5';
// import { getWinProbability } from './utils/getWinProbability';
import useSpacebarPress from '../game/hooks/useSpacebarPress';

import './styles/styles.scss';
import useMutePress from '../game/hooks/useMutePress';
import useSound from 'use-sound';
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  isAfter,
  startOfHour,
} from 'date-fns';
import WinnerModal from './WinnerModal';
import { PrizeType } from './utils';
// import { on } from 'events';
import { PlayerProvider } from './context/PlayerContext';
import { useResultsState } from './hooks/useResultsState';
import { useKeyPress } from 'react-use';

const GameV2 = () => {
  const [autoPlay, setAutoPlay] = useState<boolean>(false);
  const [muteAll, setMuteAll] = useState<boolean>(true);
  const [muteMusic, setMuteMusic] = useState<boolean>(true);
  const autoPlayRef = useRef(false);
  const autoplayTimeout = useRef<NodeJS.Timer | number | null>(null);
  // const [animationKey, setAnimationKey] = useState(0);
  // const [lastSpinResult, setLastSpinResult] = useState('No Winner!');
  // const [highestScore, setHighestScore] = useState<number>(102985);
  const { isPressed } = useSpacebarPress();
  const { isAPressed, isMPressed } = useMutePress();
  const {
    isOpen: isOpenResults,
    onOpen: onOpenResults,
    onClose: onCloseResults,
  } = useDisclosure();
  const {
    isOpen: isOpenStart,
    onOpen: onOpenStart,
    onClose: onCloseStart,
  } = useDisclosure();
  const {
    isOpen: isOpenWinner,
    onClose: onCloseWinner,
    onToggle: onToggleWinner,
  } = useDisclosure();
  const [isSpinning, setIsSpinning] = useState(false);

  const [winnerCompleted, setWinnerCompleted] = useState(false);

  // const videoRef = useRef<HTMLVideoElement>(null);
  // const [isPlaying, setIsPlaying] = useState(false);

  //========= Sounds =========//

  const [play, { duration, pause, sound: soundPlay }] = useSound(
    '../../assets/smw.mp3',
    {
      loop: true,
      volume: muteAll && muteMusic ? 0.5 : 0,
      interrupt: true,
      soundEnabled: muteAll && muteMusic,
    }
  );

  const [playFireworks, { stop: stopFireworks }] = useSound(
    '../../assets/fireworks.mp3',
    {
      loop: true,
      volume: muteAll && muteMusic ? 0.5 : 0,
      interrupt: true,
      soundEnabled: muteAll && muteMusic,
    }
  );

  const [
    play2,
    {
      //stop: stop2,

      sound: reelSound,
    },
  ] = useSound('../../assets/reelspin.wav', {
    loop: true,
    volume: muteAll ? 0.5 : 0,
    interrupt: true,
    soundEnabled: muteAll,
  });

  const [
    playWinner,
    //{ sound, stop: stopWinner }
  ] = useSound('../../assets/winner-new.mp3', {
    volume: muteAll ? 1 : 0,
    interrupt: true,
    soundEnabled: muteAll,
  });

  const [playCoinsFall] = useSound('../../assets/coins-fall.mp3', {
    volume: muteAll ? 1 : 0,
    interrupt: true,
    soundEnabled: muteAll,
    playbackRate: 1.25,
  });

  const [playBell, { stop: stopBell }] = useSound(
    '../../assets/winner-bell.wav',
    {
      volume: muteAll ? 1 : 0,
      interrupt: true,
      soundEnabled: muteAll,
    }
  );

  const [playSelection, { stop: stopSelection }] = useSound(
    '../../assets/reelselection.wav',
    {
      volume: muteAll ? 1 : 0,
      interrupt: true,
      soundEnabled: muteAll,
    }
  );
  const [playSelection2, { stop: stopSelection2 }] = useSound(
    '../../assets/reelselection2.wav',
    {
      volume: muteAll ? 1 : 0,
      interrupt: true,
      soundEnabled: muteAll,
    }
  );
  const [playSelection3, { stop: stopSelection3 }] = useSound(
    '../../assets/reelselection3.wav',
    {
      volume: muteAll ? 1 : 0,
      interrupt: true,
      soundEnabled: muteAll,
    }
  );

  // const highestScore = 102985;

  const {
    handleOpen,
    player,
    setPlayer,
    leaderboard,
    kdeData,
    results,
    isStatsLoading,
    isPlotLoading,
  } = useResultsState({
    onOpen: onOpenResults,
  });

  const containerRef = useRef<HTMLDivElement>(null);

  // const { isOpen: isOpen2, onToggle } = useDisclosure();

  // Hide cursor after 3 seconds of inactivity
  const cursorHideTimeoutRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    const hideCursor = () => {
      document.body.style.cursor = 'none';
    };

    const showCursor = () => {
      document.body.style.cursor = 'default';
    };

    const resetCursorTimeout = () => {
      if (cursorHideTimeoutRef.current) {
        clearTimeout(cursorHideTimeoutRef.current);
      }
      showCursor();
      cursorHideTimeoutRef.current = setTimeout(hideCursor, 3000);
    };

    window.addEventListener('mousemove', resetCursorTimeout);

    return () => {
      window.removeEventListener('mousemove', resetCursorTimeout);
      if (cursorHideTimeoutRef.current) {
        clearTimeout(cursorHideTimeoutRef.current);
      }
    };
  }, []);

  // Start Spin & Autoplay after 10 minutes of inactivity
  useEffect(() => {
    if (isPressed && !isSpinning) {
      // setAnimationKey((prevKey) => prevKey + 1);
      // Autoplay
      setAutoPlay(false);
      autoPlayRef.current = false;
      clearTimeout(autoplayTimeout.current as number);
      autoplayTimeout.current = setTimeout(() => {
        setAutoPlay(true);
        autoPlayRef.current = true;
        onCloseResults();
      }, 600000); // 10 Minutes
    }
  }, [isPressed, isSpinning]);

  const handleCompletedSpins = ({
    seniority,
    seniorityShortNames,
    role,
    roleShortNames,
    msa,
    msaShortNames,
    winner,
    salary,
  }: any) => {
    // setWinnerCompleted(true);
    console.log('Spins Done!');
    console.log('Seniority:', seniority);
    console.log('Role:', role);
    console.log('MSA:', msa);
    console.log('Winner:', winner);
    console.log('grandPrizeCount:', grandPrizeCount);
    console.log('megaPrizeCount:', megaPrizeCount);
    console.log('ultimatePrizeCount:', ultimatePrizeCount);
    reelSound.stop();
    // pause();
    if (winner === PrizeType.GRAND) {
      playWinner();
      onToggleWinner();
      setTimeout(() => {
        handleOpen({
          seniority: {
            id: seniority,
            shortName: seniorityShortNames,
          },
          msa: { id: msa, shortName: msaShortNames },
          rolek150: { id: role, shortName: roleShortNames },
          salary: salary,
        });
      }, 4500);
    } else if (winner === PrizeType.MEGA) {
      playBell();
      onToggleWinner();
      handleOpen({
        seniority: {
          id: seniority,
          shortName: seniorityShortNames,
        },
        msa: { id: msa, shortName: msaShortNames },
        rolek150: { id: role, shortName: roleShortNames },
        salary: salary,
      });
    } else if (winner === PrizeType.ULTIMATE) {
      playBell();
      onToggleWinner();
      handleOpen({
        seniority: {
          id: seniority,
          shortName: seniorityShortNames,
        },
        msa: { id: msa, shortName: msaShortNames },
        rolek150: { id: role, shortName: roleShortNames },
        salary: salary,
      });
    } else {
      // playWinner();
      // setTimeout(() => {
      //   soundPlay.play();
      // }, 9000);

      handleOpen({
        seniority: {
          id: seniority,
          shortName: seniorityShortNames,
        },
        msa: { id: msa, shortName: msaShortNames },
        rolek150: { id: role, shortName: roleShortNames },
        salary: salary,
      });
    }
  };

  useEffect(() => {
    if (duration) {
      play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  useEffect(() => {
    if (isAPressed) {
      setMuteAll((prevState) => !prevState);
    }
  }, [isAPressed]);

  useEffect(() => {
    if (isMPressed) {
      setMuteMusic((prevState) => !prevState);
    }
  }, [isMPressed]);

  //Winrate Calculations
  // Define the max number of prizes allowed per hour
  const maxGrandPrizesPerHour = 1;
  const maxMegaPrizesPerHour = 2;
  const maxUltimatePrizesPerHour = 1;

  // State to track the number of each prize given out
  const [grandPrizeCount, setGrandPrizeCount] = useState(0);
  const [megaPrizeCount, setMegaPrizeCount] = useState(0);
  const [ultimatePrizeCount, setUltimatePrizeCount] = useState(0);
  const [randomValueSaved, setRandomValueSaved] = useState(0);
  const [spinCount, setSpinCount] = useState(0);
  const [lastPrizeSpinCount, setLastPrizeSpinCount] = useState(0);

  // State for the prize result
  const [prizeResult, setPrizeResult] = useState(PrizeType.NONE);

  // State to keep track of the time for resetting the prize counters
  const [nextResetTime, setNextResetTime] = useState(() => getNextTopOfHour());

  // State to track the last prize time (for 5-minute cooldown)
  const [lastPrizeTime, setLastPrizeTime] = useState<Date | null>(null);

  // Function to calculate the next top of the hour using date-fns
  function getNextTopOfHour() {
    const now = new Date();
    const nextHour = addHours(startOfHour(now), 1);
    return nextHour;
  }

  // Check every second if it's time to reset the counters
  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() >= nextResetTime.getTime()) {
        resetPrizeCounters();
      }
    }, 1000); // Check every second
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextResetTime]);

  // Function to reset prize counts at the top of the hour
  const resetPrizeCounters = useCallback(() => {
    setGrandPrizeCount(0);
    setMegaPrizeCount(0);
    setUltimatePrizeCount(0);
    setNextResetTime(getNextTopOfHour());
  }, []);

  // Calculate the current increase in probabilities based on time elapsed
  const getCurrentProbabilityIncrease = () => {
    const now = new Date();
    const minutesPassed = differenceInMinutes(now, startOfHour(now));
    const intervalsPassed = Math.floor(minutesPassed / 10);
    return intervalsPassed * 0.01;
  };

  // Handle winrate calculations
  const getPrizeLevel = (): PrizeType => {
    setSpinCount((previous) => previous + 1);
    const now = new Date();

    // Check if 5 minutes have passed since the last prize and 15 spins have occurred since the last prize
    const fiveMinutesLater = lastPrizeTime
      ? addMinutes(lastPrizeTime, 5)
      : null;
    const canAwardPrize =
      !lastPrizeTime ||
      (fiveMinutesLater && isAfter(now, fiveMinutesLater)) ||
      spinCount - lastPrizeSpinCount >= 2;

    if (!canAwardPrize) {
      setPrizeResult(PrizeType.NONE);
      return PrizeType.NONE;
    }

    // Calculate the base probabilities
    let grandPrizeProbability = 0.03; // 0.5% base chance
    let megaPrizeProbability = 0.08; // 1% base chance
    let ultimatePrizeProbability = 0.13; // 5% base chance

    // Increase the probabilities based on the time passed in the current hour
    const probabilityIncrease = getCurrentProbabilityIncrease();

    grandPrizeProbability += probabilityIncrease / 5;
    megaPrizeProbability += probabilityIncrease;
    ultimatePrizeProbability += probabilityIncrease;

    // console.log('grandPrizeProbability', grandPrizeProbability);
    // console.log('megaPrizeProbability', megaPrizeProbability);
    // console.log('ultimatePrizeProbability', ultimatePrizeProbability);

    // Randomize the prize (for simplicity, using random numbers)
    const randomValue = Math.random();
    setRandomValueSaved(randomValue);

    if (
      randomValue < grandPrizeProbability &&
      grandPrizeCount < maxGrandPrizesPerHour
    ) {
      // 0.5% chance for grand prize
      setGrandPrizeCount(grandPrizeCount + 1);
      setPrizeResult(PrizeType.GRAND);
      setLastPrizeTime(now);
      setLastPrizeSpinCount(spinCount);
      return PrizeType.GRAND;
    } else if (
      randomValue < megaPrizeProbability &&
      megaPrizeCount < maxMegaPrizesPerHour
    ) {
      // 1% chance for mega prize
      setMegaPrizeCount(megaPrizeCount + 1);
      setPrizeResult(PrizeType.MEGA);
      setLastPrizeTime(now);
      setLastPrizeSpinCount(spinCount);
      return PrizeType.MEGA;
    } else if (
      randomValue < ultimatePrizeProbability &&
      ultimatePrizeCount < maxUltimatePrizesPerHour
    ) {
      // 5% chance for ultimate prize
      setUltimatePrizeCount(ultimatePrizeCount + 1);
      setPrizeResult(PrizeType.ULTIMATE);
      setLastPrizeTime(now);
      setLastPrizeSpinCount(spinCount);
      return PrizeType.ULTIMATE;
    } else {
      // No prize
      setPrizeResult(PrizeType.NONE);
      return PrizeType.NONE;
    }
  };
  const [isESCPressed] = useKeyPress('Escape');

  const resetPlayer = () => {
    setPlayer({ email: '', playerId: '' });
  };

  useEffect(() => {
    if (isESCPressed) {
      if (isOpenResults) {
        onCloseResults();
      }

      resetPlayer();
    }
  }, [isESCPressed]);

  // let timer: any;

  // // set initial player idle til player reset
  // useEffect(() => {
  //   timer = setTimeout(() => {
  //     resetPlayer();
  //   }, 600000);
  // }, []);

  // // reset player idle timer after period of inactivity
  // useEffect(() => {
  //   if (timer) {
  //     clearTimeout(timer);
  //   }

  //   timer = setTimeout(() => {
  //     resetPlayer();
  //   }, 600000);
  // }, [isPressed]);

  return (
    <PlayerProvider
      player={player}
      setPlayer={setPlayer}
      resetPlayer={resetPlayer}
    >
      <Flex
        className="v2Wrapper"
        w="full"
        h="100vh"
        // bgColor="#2f1e51"
        bgColor="black"
        direction="column"
        align="center"
        // justify="flex-end"
        // pointerEvents="none"
        // userSelect="none"
        position="relative"
      >
        {/* <video
        src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/lights-bg-blur-1080p.mov"
        autoPlay
        loop
        playsInline
        muted
        style={{
          zIndex: 0,
          objectFit: 'cover',
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      /> */}
        {/* <video
        ref={videoRef}
        src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/coins-grand-win.mov"
        autoPlay
        loop
        playsInline
        muted
        style={{
          zIndex: 100,
          objectFit: 'cover',
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      /> */}
        <WinnerModal
          isOpen={isOpenWinner}
          onClose={() => null}
          prizeResult={prizeResult}
          playFireworks={playFireworks}
          stopFireworks={stopFireworks}
          playCoinsFall={playCoinsFall}
        />
        <Grid
          flexDirection="column"
          color="white"
          templateRows="repeat(20, 1fr)"
          h="full"
          w="full"
        >
          <GridItem
            rowSpan={11}
            ref={containerRef}
            position="relative"
            zIndex={1}
          >
            <MainTop />

            <StartModal
              isOpen={isOpenStart}
              onClose={() => {
                onCloseStart();
              }}
            />
            <ResultsModal
              isOpen={isOpenResults}
              onClose={onCloseResults}
              data={results}
              kdeData={kdeData}
              winner={[]}
              containerRef={containerRef}
              leaderboard={leaderboard}
              isLoading={isStatsLoading || isPlotLoading}
            />
          </GridItem>
          <GridItem
            rowSpan={9}
            display="flex"
            flexDirection="column"
            w="full"
            h="full"
            position="relative"
            overflow="hidden"
          >
            <video
              src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/gold-neon-border.mov"
              autoPlay
              loop
              playsInline
              muted
              style={{
                width: '100%',
                height: '61%',
                mixBlendMode: 'plus-lighter',
                position: 'absolute',
                zIndex: 20,
                // top: 0,
                // bottom: 0,
                // left: 0,
                // right: 0,
                top: '14%',
                objectFit: 'fill',
                transform: 'scaleX(1.17) scaleY(1.7)',
                opacity: 0.7,
              }}
            />
            <video
              src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/palm-leafs.mov"
              autoPlay
              loop
              playsInline
              muted
              style={{
                width: '100%',
                height: '100%',
                mixBlendMode: 'screen',
                position: 'absolute',
                zIndex: 20,
                // top: 0,
                // bottom: 0,
                // left: 0,
                // right: 0,
                top: '-14%',
                objectFit: 'fill',
                // transform: 'scaleX(1.17) scaleY(1.7)',
                transform: 'scale(1.5)',
                opacity: 0.7,
              }}
            />
            {/* <video
              src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/light-border.m4v"
              autoPlay
              loop
              playsInline
              muted
              style={{
                width: '100%',
                height: '100%',
                mixBlendMode: 'screen',
                position: 'absolute',
                zIndex: 21,
                // top: 0,
                // bottom: 0,
                // left: 0,
                // right: 0,
                // top: '-14%',
                objectFit: 'fill',
                transform: 'scaleX(1.17) scaleY(1.2)',
                // transform: 'scale(1.5)',
                opacity: 1,
              }}
            /> */}
            <video
              src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/fluid-bg-480p.mov"
              autoPlay
              loop
              playsInline
              muted
              style={{
                width: '100%',
                height: '91%',
                mixBlendMode: 'soft-light',
                position: 'absolute',
                zIndex: 10,
                // top: 0,
                // bottom: 0,
                // left: 0,
                // right: 0,
                // top: '14%',
                objectFit: 'fill',
                // transform: 'scaleX(1.17) scaleY(1.7)',
                opacity: 0.5,
                // transform: 'scale(1)',
              }}
            />
            <ReelsV5
              isPressed={isPressed}
              isOpen={isOpenResults}
              onClose={onCloseResults}
              isSpinning={isSpinning}
              setIsSpinning={setIsSpinning}
              autoPlay={autoPlay}
              autoPlayRef={autoPlayRef}
              handleCompletedSpins={handleCompletedSpins}
              isOpenStart={isOpenStart}
              onOpenStart={onOpenStart}
              onCloseStart={onCloseStart}
              play2={play2}
              playSelection={playSelection}
              playSelection2={playSelection2}
              playSelection3={playSelection3}
              getPrizeLevel={getPrizeLevel}
              onCloseWinner={onCloseWinner}
              isResultsOpen={isOpenResults}
              onResultsClose={onCloseResults}
              setWinnerCompleted={setWinnerCompleted}
              isOpenWinner={isOpenWinner}
              isEscPressed={isESCPressed}
              reelSound={reelSound}
            />
            <InfoBar />
          </GridItem>
        </Grid>
      </Flex>
    </PlayerProvider>
  );
};

export default GameV2;
