/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { scaleSqrt, min, max } from 'd3';
import 'mapbox-gl/src/css/mapbox-gl.css';
import './custom-mapbox.css';
import './popup-css.css';
import 'css-skeletons/dist/css-skeletons.min.css';
import { debounce, set, isUndefined } from 'lodash';
import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { removeLoadingStatus } from '@revelio/core';
import { popupHTML, popupHTMLGenInterface } from './popup-html';
import { format as d3Format } from 'd3';
import { fetchTooltipData } from './helpers';
import type { FeatureCollection } from 'geojson';
import { getTalentDiscoveryInput } from './td-utils';
import { useTalentDiscoveryPreFetch } from './use-talent-discovery-pre-fetch';

export type PopupDataKey = Omit<popupHTMLGenInterface, 'header' | 'isLoading'>;

interface TalentDiscoveryMapProps {
  data: {
    data?: any[];
    filters?: any;
    popupDataKeys?: PopupDataKey[];
  };
  isRenderingOrLoading?: any;
  minZoomLevel?: number;
  maxZoomLevel?: number;
  tooltipHeight?: number;
  initialCoords?: { lng?: number; lat?: number };
  tooltipRequestOverrides?: {
    [key: string]: string | number | boolean | number[] | undefined;
  };
  displayMSAsForCountry?: boolean;
  displayPointsRegardlessOfZoom?: boolean;
  headcountFromPostings?: boolean;
}

let latestTooltipPropertiesRequested = null;
let prevPoint: number | null = null;

const MAPBOX_TOKEN =
  'pk.eyJ1IjoicmV2ZWxpb2xhYnMiLCJhIjoiY2w3N3pjM2tlMDRuczN2cXB1ZTU3bWZvdyJ9.AluK-n7WOv2tAOhTL-ZzAQ';
mapboxgl.accessToken = MAPBOX_TOKEN;

const CIRCLE_SCALE_FACTOR = 1.1;

const TalentDiscoveryMap = (props: TalentDiscoveryMapProps) => {
  const {
    data: {
      data = [],
      filters,
      // dimension
      popupDataKeys = [
        'profiles',
        'growth',
        'postings',
        'timeToFill',
        'marketTightness',
        'salaryDist',
      ] as PopupDataKey[],
    },
    isRenderingOrLoading,
    minZoomLevel = 1.8,
    maxZoomLevel = 6,
    tooltipHeight,
    initialCoords,
    tooltipRequestOverrides,
    displayMSAsForCountry,
    displayPointsRegardlessOfZoom,
    headcountFromPostings,
  } = props;

  const mapContainer = useRef<HTMLElement>();
  const map = useRef<mapboxgl.Map>();
  const popup = useRef<mapboxgl.Popup>();
  const [zoom] = useState(minZoomLevel);
  const lng = initialCoords?.lng || 30.0;
  const lat = initialCoords?.lat || -40.0;
  const [sourceDataLoaded, setSourceDataLoaded] = useState(false);
  const retryCountRef = useRef(0);
  const zoomRef = useRef(true);

  const [mapLoaded, setMapLoaded] = useState(false);

  const [zoomLevel, setZoomLevel] = useState(minZoomLevel * 10);

  const filterPopupData = useCallback(
    <T extends popupHTMLGenInterface>(obj: T): popupHTMLGenInterface => {
      return {
        header: obj.header,
        isLoading: obj.isLoading,
        height: tooltipHeight,
        ...Object.keys(obj)
          .filter((key) => popupDataKeys.includes(key as PopupDataKey))
          .reduce((acc, key) => {
            acc[key as keyof T] = obj[key as keyof T];
            return acc;
          }, {} as Partial<T>),
      };
    },
    [popupDataKeys, tooltipHeight]
  );

  const transformed_map_data = useCallback(
    (d: any) => {
      const counts: Record<string, number> = {};

      const shareData = d.map((row: any) => {
        const type = row.type;
        let currentCount = counts[type] || 0;

        set(counts, type, ++currentCount);

        return row.share;
      });

      const circleScaleFn = scaleSqrt().domain([
        min(shareData),
        max(shareData) as any,
      ]);
      return d
        ? {
            type: 'FeatureCollection',
            features:
              d.map((mapdata: any) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { id, lat, lon, count, share, location, type } = mapdata;

                const circleScaleRange = (() => {
                  if (type === 2 && !displayMSAsForCountry) {
                    if (counts[type] === 1) {
                      return [35, 50]; // 1 msa
                    } else {
                      return [4, 85]; // 2+ msa
                    }
                  } else {
                    if (counts[type] === 1) {
                      return [35, 50]; // 1 country
                    } else {
                      return [3, 35]; // 2+ countries
                    }
                  }
                })();

                const circleScale = circleScaleFn.range(circleScaleRange);

                const initialPopupData = {
                  header: `${location}`,
                  profiles: `${d3Format(',')(count)}`,
                  headcount: `${d3Format(',')(count)}`,
                  growth: '+5',
                  postings: '3,497',
                  timeToFill: '44 days',
                  marketTightness: '75', //calcMarketTightness(postings, countRaw),
                  salaryDist: ['$10k', '$50k', '$90k'],
                  isLoading: true,
                };

                const popupData = {
                  ...filterPopupData(initialPopupData),
                };

                return {
                  type: 'Feature',
                  properties: {
                    id,
                    description: popupHTML(popupData),
                    counts: count || 0,
                    share: circleScale(share * CIRCLE_SCALE_FACTOR) || 0,
                    location: location || 'default',
                    type,
                    shareWidth: circleScale(share) < 4 ? 1 : 2,
                    ...popupData,
                  },
                  geometry: {
                    type: 'Point',
                    coordinates: [lon || 0, lat || 0],
                  },
                };
              }) || [],
          }
        : {
            type: 'FeatureCollection',
            features: [],
          };
    },
    [filterPopupData, displayMSAsForCountry]
  );

  useEffect(() => {
    if (map.current) {
      return;
    } // initialize map only once

    const initializeMap = () => {
      map.current = new mapboxgl.Map({
        container: mapContainer.current as HTMLElement,
        style: 'mapbox://styles/reveliolabs/clmz1ykf3063001qx198la29n',
        center: [lat, lng],
        zoom: zoom,
        minZoom: minZoomLevel,
        maxZoom: maxZoomLevel,
        attributionControl: false,
      });
      const mapCur = map.current;
      map.current.on('load', () => {
        setMapLoaded(true);
        mapCur.addSource('places', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        });
        popup.current = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          className: 'mbpopup',
        });
        mapCur.dragRotate.disable();
        mapCur.touchZoomRotate.disableRotation();
        mapCur.on('zoom', function () {
          const zoom = mapCur.getZoom() * 10;
          setZoomLevel(zoom);
        });

        mapCur.on('mousemove', 'places-1', handleMouseEnterPoint);
        mapCur.on('mousemove', 'places-2', handleMouseEnterPoint);

        mapCur.on('mouseleave', 'places-1', handleMouseLeavePoint);
        mapCur.on('mouseleave', 'places-2', handleMouseLeavePoint);

        removeLoadingStatus(['tabChange']);
        isRenderingOrLoading?.next(false);
      });

      mapCur.on('data', (e: any) => {
        if (e?.source?.type === 'geojson') {
          addPoints(mapCur, popup.current as mapboxgl.Popup, e.source.data);
        }
      });

      mapCur.on('sourcedata', (e: any) => {
        if (e?.isSourceLoaded && e?.sourceId === 'places') {
          setSourceDataLoaded(true);
        }
      });

      mapCur.on('error', async (e: any) => {
        if (e.error && e.error.status === 403 && retryCountRef.current < 4) {
          retryCountRef.current += 1;
          await clearMapboxCache();
          // Destroy the current map
          mapCur.remove();
          // Reinitialize the map
          initializeMap();
        }
      });
    };

    const clearMapboxCache = async () => {
      if ('caches' in window) {
        const cacheNames = await caches.keys();
        cacheNames.forEach(async (cacheName) => {
          if (cacheName.includes('mapbox-tiles')) {
            await caches.delete(cacheName);
            console.log(`Cleared cache: ${cacheName}`);
          }
        });
      }
    };

    initializeMap();

    const resizer = new ResizeObserver(
      debounce(() => {
        map.current?.resize();
      }, 100)
    );

    resizer.observe(mapContainer.current as HTMLElement);

    return () => {
      resizer.disconnect();
      map.current?.remove();
    };

    // eslint-disable-next-line
  }, [isRenderingOrLoading, lat, lng, zoom, retryCountRef.current]);

  const placeMap: { [key: string]: any } = useMemo(
    () => ({
      'places-1': { min: 1, max: 3 }, // GLOBAL
      'places-2': { min: 3, max: 7 }, // MSA
    }),
    []
  );

  const addPoints = (map: mapboxgl.Map, popup: mapboxgl.Popup, d: any) => {
    for (const feature of d.features) {
      const symbol = feature.properties.type;
      const layerID = `places-${symbol}`;

      // Add a layer for this symbol type if it hasn't been added already.
      if (!map.getLayer(layerID)) {
        map.addLayer({
          id: layerID,
          type: 'circle',
          source: 'places',
          paint: {
            'circle-color': '#5BD992',
            'circle-radius': ['number', ['get', 'share'], 0],
            'circle-stroke-width': ['number', ['get', 'shareWidth'], 0],
            'circle-stroke-color': '#ffffff',
            'circle-opacity': 0.9,
          },
          filter: ['==', 'type', feature.properties.type],
          minzoom: displayPointsRegardlessOfZoom ? 0 : placeMap[layerID].min,
          maxzoom: displayPointsRegardlessOfZoom ? 24 : placeMap[layerID].max,
        });

        map.on('zoom', () => {
          popup.remove();
        });
      }
    }
  };

  const handleMouseLeavePoint = () => {
    prevPoint = null;

    if (map.current) {
      map.current.getCanvas().style.cursor = '';
    }

    if (popup.current) {
      popup.current.remove();
    }
  };

  const handleMouseEnterPoint = async (e: any) => {
    if (!map.current) {
      return;
    }

    if (!popup.current) {
      return;
    }

    const currentPoint = e.features[0].properties.id;

    if (prevPoint === currentPoint) {
      return;
    }

    prevPoint = currentPoint;

    // const zoomLevel = map.current.getZoom();
    // if (placeMap[layerID].min > zoomLevel || placeMap[layerID].max < zoomLevel)
    //   return;

    // Change the cursor style as a UI indicator.
    map.current.getCanvas().style.cursor = 'pointer';

    const properties = e.features[0].properties;
    latestTooltipPropertiesRequested = properties;

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const description = e.features[0].properties.description;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.current
      .setLngLat(coordinates)
      .setHTML(description)
      .addTo(map.current);

    const vars = getTalentDiscoveryInput({
      id: properties.id,
      type: properties.type,
    });

    const profiles = properties.counts;

    // use no data popup as the default and update it if we do have data
    let initialUpdatedPopupData = {
      header: `${properties.location}`,
      profiles: `${d3Format(',')(profiles)}`,
      headcount: `${d3Format(',')(profiles)}`,
      growth: '--',
      postings: '--',
      timeToFill: '--',
      marketTightness: '--',
      salaryDist: [],
      isLoading: true,
    };

    let updatedPopupData = {
      ...filterPopupData(initialUpdatedPopupData),
    };

    try {
      const parsedVars = {
        ...vars,
        provider: [9],
        ...tooltipRequestOverrides,
      };
      const res = await fetchTooltipData(parsedVars);

      const {
        growth,
        postings,
        timeToFill,
        marketTightness,
        salaryDist,
        headcount,
      } = res;

      const originalTooltipPropertiesRequestId = properties.id;

      if (
        latestTooltipPropertiesRequested.id !==
        originalTooltipPropertiesRequestId
      ) {
        return;
      }

      initialUpdatedPopupData = {
        header: `${properties.location}`,
        profiles: `${d3Format(',')(profiles)}`,
        headcount: `${d3Format(',')(headcountFromPostings && headcount ? headcount.toFixed(0) : profiles)}`,
        growth: `${isNaN(growth) ? '--' : d3Format('.1%')(growth)}`,
        postings: `${d3Format(',')(postings)}`,
        timeToFill: `${isNaN(timeToFill) ? '--' : d3Format('d')(timeToFill)}`,
        marketTightness,
        salaryDist: salaryDist.map((d: number) => d3Format('$.2s')(d)),
        isLoading: false,
      };

      updatedPopupData = {
        ...filterPopupData(initialUpdatedPopupData),
      };
    } catch (err) {
      updatedPopupData.isLoading = false;
    }

    popup.current.setHTML(popupHTML(updatedPopupData));
  };

  const zoomToLargest = useCallback(() => {
    let largestCountry = { lat: 0, lon: 0 };
    let largestMSA: any = { id: null, share: 0 };
    const firstRender = zoomRef.current;

    if (data.length > 0) {
      largestCountry = data
        .filter((d: any) => {
          const isMSA = d.type === 2;
          const isLargestShareMSA = d.share >= largestMSA.share;

          if (isMSA && (isLargestShareMSA || largestMSA.id === null)) {
            largestMSA = d;
          }

          return d.type === 1;
        })
        .reduce(
          (a: any, b: any) => (a.share > b.share ? a : b),
          largestCountry
        );

      const activeMSAValues = filters?.find(
        (fil: any) => fil.id === 'metro_area'
      )?.value;

      const largestActiveMSA = activeMSAValues?.find((activeMSA: any) => {
        return Number(activeMSA.id) === Number(largestMSA?.id);
      });

      const hasLargestActiveMSA =
        (!isUndefined(largestActiveMSA) && largestMSA.id !== null) ||
        (largestMSA.id !== null && displayMSAsForCountry);

      const coordinatesToFlyTo: any = hasLargestActiveMSA
        ? [largestMSA.lon, largestMSA.lat]
        : [largestCountry.lon, largestCountry.lat];

      if (hasLargestActiveMSA) {
        map.current?.setZoom(placeMap['places-2'].min);
      }

      if (!initialCoords && firstRender) {
        (map.current as mapboxgl.Map).easeTo({
          center: coordinatesToFlyTo,
          offset: [0, -100],
          zoom: hasLargestActiveMSA ? 3 : minZoomLevel,
        });
        zoomRef.current = false;
      }
    }
  }, [
    data,
    filters,
    placeMap,
    minZoomLevel,
    initialCoords,
    displayMSAsForCountry,
  ]);

  useEffect(() => {
    if (map.current && data && sourceDataLoaded) {
      const placesDataSource = map.current.getSource(
        'places'
      ) as mapboxgl.GeoJSONSource;
      if (placesDataSource) {
        // filter out empty location data
        const filteredData = data.filter((d) => !!d.location);
        const transformedData = transformed_map_data(filteredData);
        placesDataSource.setData(transformedData as FeatureCollection);

        zoomToLargest();
      }
    }
  }, [data, sourceDataLoaded, zoomToLargest, transformed_map_data]);

  useTalentDiscoveryPreFetch({ map: map.current });

  return (
    <div
      ref={mapContainer as any}
      className="map-container"
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
      }}
    >
      {mapLoaded && (
        <Box
          zIndex={1}
          width="25%"
          minW="120px"
          maxW="220px"
          position="absolute"
          bottom="0"
          right="0"
          padding="8px 16px 0px 16px"
        >
          <Slider
            aria-label="slider-ex-1"
            defaultValue={minZoomLevel * 10}
            value={zoomLevel}
            focusThumbOnChange={false}
            zIndex={2}
            onChange={(val) => {
              setZoomLevel(val);
              map.current?.setZoom(val / 10);
            }}
            min={minZoomLevel * 10}
            max={maxZoomLevel * 10}
          >
            <SliderTrack backgroundColor="#ffffffc0">
              <SliderFilledTrack backgroundColor="green.500" />
            </SliderTrack>
            <SliderThumb backgroundColor="green.500" />
          </Slider>
        </Box>
      )}
    </div>
  );
};

TalentDiscoveryMap.defaultProps = {
  data: [],
  mapFullscreen: false,
};

export default TalentDiscoveryMap;
