import { Box, Flex } from '@chakra-ui/layout';

import prizeLevelsImages from './images/prize-levels-only.png';
import revelioSlotsLogo from '../game/assets/revelio-slots-logo.png';

const MainTop = () => {
  return (
    <Box
      h="full"
      w="full"
      // bgColor="rgba(80,20,110,0.75)"
      position="relative"
    >
      <video
        src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/coins-falling.mov"
        autoPlay
        loop
        playsInline
        muted
        style={{
          width: '100%',
          height: '100%',
          mixBlendMode: 'color-dodge',
          position: 'absolute',
          zIndex: 10,
          // top: 0,
          // bottom: 0,
          // left: 0,
          // right: 0,
          // top: '14%',
          objectFit: 'cover',
          // transform: 'scaleX(1.17) scaleY(1.7)',
          opacity: 1,
          transform: 'scale(1)',
        }}
      />
      <video
        src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/geo-neon-frame.mov"
        autoPlay
        loop
        playsInline
        muted
        style={{
          width: '100%',
          height: '100%',
          mixBlendMode: 'screen',
          position: 'absolute',
          zIndex: 11,
          // top: 0,
          // bottom: 0,
          // left: 0,
          // right: 0,
          // top: '14%',
          objectFit: 'fill',
          // transform: 'scaleX(1.17) scaleY(1.7)',
          opacity: 0.7,
          transform: 'scale(1.3) rotate(90deg)',
        }}
      />
      <video
        // src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/neon-city-720p.mov"
        src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/neon-glowing-city.mov"
        autoPlay
        loop
        playsInline
        muted
        style={{
          zIndex: 0,
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          // position: 'fixed',
          top: 0,
          left: 0,
          // mixBlendMode: 'lighten',
          mixBlendMode: 'color-dodge',
        }}
      />
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        w="full"
        position="absolute"
        top={0}
        left={0}
        zIndex={20}
        // gap={4}
        mt={2}
      >
        <img
          src={revelioSlotsLogo}
          alt="logo"
          style={{
            height: '20vw',
            width: 'auto',
            marginBottom: '-20px',
            marginLeft: '20px',
          }}
        />
        <img
          src={prizeLevelsImages}
          alt="logo"
          style={{ height: '55vw', width: 'auto' }}
        />
      </Flex>
    </Box>
  );
};

export default MainTop;
