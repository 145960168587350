import { Route, Routes, Navigate } from 'react-router-dom';
import { enableElfProdMode } from '@ngneat/elf';
import { useBreakpoint } from '@chakra-ui/media-query';

import { LOGIN, PagePaths } from '@revelio/core';
import '../styles/main.scss';
import './next.module.scss';

import SideNav from './routes/side-nav/side-nav';
import { RequireAuth } from './routes/ProtectedRoute';
import { navRouteItems } from './routes/routes';
import { reducedRoutes } from './routes/reduced-routes';
import { PrivateRoute } from './routes/private-route';

// Pages outside of routes
import Landing from './landing/Landing';
import Game from './game/Game';
import GameV2 from './gameV2/GameV2';
import Login from './login/login';
// temporary routes for pages still being built
import { GeographySummaryWithChartRenderingProvider } from './entity-summary/geography-summary/geography-summary';
import { RoleSummary } from './entity-summary/role-summary/role-summary';

import { MobileUnsupportedPage } from './MobileUnsupportedPage';

import useGoogleAnalytics from '../hooks/analytics/useGoogleAnalytics';
import { environment } from '../environments/environment';

import packageJson from '../../package.json';
const version = packageJson.version;

export function Next() {
  useGoogleAnalytics();
  if (environment.production) {
    enableElfProdMode();
  }

  const breakpoint = useBreakpoint({ ssr: false });
  if (
    environment.production &&
    (breakpoint === 'sm' || breakpoint === 'base')
  ) {
    return <MobileUnsupportedPage />;
  }

  return (
    <Routes>
      <Route path={`/${LOGIN}`} element={<Login />} />
      <Route
        path={'/job-queue'}
        loader={() => {
          return new Promise((resolve) => {
            window.location.replace('https://pipelines.reveliolabs.com');
            resolve({});
          });
        }}
      />
      <Route path="/" element={<PrivateRoute />}>
        <Route element={<SideNav items={navRouteItems} version={version} />}>
          <Route index element={<Landing />} />
          {reducedRoutes}

          <Route
            path={'/' + PagePaths.GEOGRAPHY_SUMMARY}
            element={
              <RequireAuth adminRequired>
                <GeographySummaryWithChartRenderingProvider />
              </RequireAuth>
            }
          />
          <Route
            path={'/' + PagePaths.ROLE_SUMMARY}
            element={
              <RequireAuth adminRequired>
                <RoleSummary />
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path={`/game`}
          element={
            <RequireAuth adminRequired>
              <Game />
            </RequireAuth>
          }
        />
        <Route
          path={`/gameV2`}
          element={
            <RequireAuth adminRequired>
              <GameV2 />
            </RequireAuth>
          }
        />
      </Route>

      <Route path="*" element={<Navigate to={`/${LOGIN}`} />} />
    </Routes>
  );
}

export default Next;
