import React from 'react';
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalContent } from '@chakra-ui/react';
import { MailChimpEmailSubscribe } from './MailChimpEmailSubscribe';

interface StartModalProps {
  isOpen: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const StartModal = ({ isOpen, onClose, containerRef }: StartModalProps) => {
  return (
    <Modal
      onClose={onClose}
      size="full"
      isCentered
      isOpen={isOpen}
      portalProps={{ appendToParentPortal: true, containerRef }}
    >
      <ModalContent h="100%" maxH="100%" maxW="100%" background="none">
        <Grid
          flexDirection="column"
          color="white"
          templateRows="repeat(20, 1fr)"
          h="full"
          w="full"
        >
          <GridItem rowSpan={11}>
            <ModalBody background="rgba(0, 0, 0, 0.9)" h="100%" p={0}>
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                h="full"
                py={28}
                backgroundColor="rgba(20, 0, 50, 0.8)"
                zIndex={10}
              >
                <Text
                  fontSize="160px"
                  color="white"
                  lineHeight="150px"
                  textAlign="center"
                  textTransform="uppercase"
                  fontWeight={600}
                >
                  Winners
                  <br />
                  Start Here
                </Text>
                <video
                  src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/neon-arrows-start-2.mov"
                  autoPlay
                  loop
                  playsInline
                  muted
                  style={{
                    width: '100%',
                    height: '60%',
                    mixBlendMode: 'lighten',
                  }}
                />
                <Box w="80%">
                  <MailChimpEmailSubscribe isOpen={isOpen} onClose={onClose} />
                </Box>
              </Flex>
            </ModalBody>
          </GridItem>
        </Grid>
      </ModalContent>
    </Modal>
  );
};

export default StartModal;
