import { flatten, omit } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Flex, Grid, GridItem } from '@chakra-ui/layout';
import { useQuery } from 'urql';

import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
  Views,
} from '@revelio/core';
import {
  AddEntityButton,
  COMPOSITION_GET_SUMMARY_DATA,
  GET_COMPANY_ENTITY_DATA,
  createSelectableFiltersMap,
  DateRangeFormattedValues,
  DefaultDates,
  FilterChips,
  FilterChipsContainer,
  FilterContainer,
  FilterItem,
  FilterMenu,
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  INDUSTRY_AND_COMPANY_FILTERS,
  LocalSelectionCategories,
  POSTINGS_GET_ACTIVE,
  PrimaryFilterLimits,
  ROLE_GRANULARITY_FILTERS,
  SelectionCategories,
  SENTIMENT_GET_COMPANY_SUMMARY_DATA,
  serializeFilters,
  SHARED_SET_ENTITY_LIMIT,
  SKILL_GRANULARITY_FILTERS,
  useActiveFiltersState,
  useDefaultLastMonth,
  useSelectionLists,
  useStoredFilterSet,
  useSyncFiltersToSearchParams,
  useViewFilterDefaults,
  useViewFilters,
  ViewTypes,
  useRoleTaxonomySetting,
  useAdaptiveRoleTaxonomy,
} from '@revelio/filtering';

import DashboardPage from '../../DashboardPage';
import { SalaryDistribution } from './salary-distribution/salary-distribution';
import { CompanySummaryOverview } from './company-summary-overview';
import { useGetKdePlotData } from './salary-distribution/useGetKdePlotData';
import { Dimension1, Filters } from '@revelio/data-access';
import { CompetitorBarCharts } from './competitor-bar-charts/competitor-bar-charts';
import { CompanyMap } from './map/company-map';
import { useGetCompanyMapData } from './map/useGetCompanyMapData';
import { CompanyStats } from './company-stats/company-stats';
import { transformFiltersToPostingVariables } from '../../postings/utils';
import {
  ChartsRenderedContext,
  ChartsRenderedProvider,
} from './charts-rendered-context';
import {
  CompanyCompositionStatsMetric,
  CompanyPostingsStatsMetric,
  CompanySentimentMetric,
} from './company-stats/company-stat/company-stat-line-charts/utils/get-aggregated-competitor-metrics';
import { FilterChipsLoader } from '@revelio/filtering';

const SUMMARY_PRIMARY_VIEW_FILTER = [
  {
    filters: INDUSTRY_AND_COMPANY_FILTERS,
    isNested: true,
    limit: 1,
  },
];
const SUMMARY_SECONDARY_FILTERS = [
  {
    filters: ROLE_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
  {
    filters: GEOGRAPHY_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
  SelectionCategories.SENIORITY,
  {
    filters: SKILL_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
];

const CompanySummary = () => {
  const primaryFilters = flatten(
    createSelectableFiltersMap(SUMMARY_PRIMARY_VIEW_FILTER)
  ) as SelectionCategories[];

  const selectableFiltersMap = createSelectableFiltersMap(
    SUMMARY_SECONDARY_FILTERS
  );
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  useViewFilters([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    SelectionCategories.DATE_RANGE,
    SelectionCategories.DATE_RANGE_FULL,
  ]);

  useSelectionLists([
    SelectionCategories.COMPANY,
    SelectionCategories.INDUSTRY,
    ...flattenedSelectableFilters,
  ]);

  useStoredFilterSet({
    sharedSetId: FilterSets.COMPANY_SUMMARY,
    tab: ViewTypes.COMPANY,
    primaryEntitiesSync: true,
    limit: 1,
    defaultLimit: 1,
    filterNames: primaryFilters,
  });

  const viewFilterDefaultArgs = {
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.COMPANY,
    presetView: FilterSets.COMPANY_SUMMARY,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: PrimaryFilterLimits.ENTITY_SUMMARY,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };

  useViewFilterDefaults(viewFilterDefaultArgs);

  useSyncFiltersToSearchParams({
    primaryFilters,
    syncToPrimaryEntities: true,
  });

  const activeFilters = useActiveFiltersState();

  const selectedCompanyRCID = parseInt(
    (
      activeFilters.find((filter) => filter.id === SelectionCategories.COMPANY)
        ?.value as FilterItem[]
    )?.[0]?.id as string,
    10
  );

  const [{ data: entityData, fetching: primaryCompanyLoading }] = useQuery({
    query: GET_COMPANY_ENTITY_DATA,
    variables: {
      filters: {
        // secondary filters should not change the competitor search
        company: [selectedCompanyRCID],
        top_competitors: 7, //this will change to num_competitors
      },
    },
    pause: !selectedCompanyRCID,
  });

  const competitors = useMemo(
    () => entityData?.entity?.competitors || [],
    [entityData]
  );
  const primaryAndCompetitorRCIDs = useMemo(() => {
    const competitorIds = competitors
      ?.filter((competitor) => competitor?.metadata?.id)
      .map((competitor) => competitor?.metadata?.id as number);

    let ids = competitorIds || [];

    if (selectedCompanyRCID) {
      ids = [selectedCompanyRCID, ...ids];
    }

    return ids;
  }, [competitors, selectedCompanyRCID]);

  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const { kdeSalaryData, loading: kdePlotsLoading } = useGetKdePlotData(
    primaryAndCompetitorRCIDs,
    isCustomRoleTaxonomyEnabled
  );

  useDefaultLastMonth({
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.COMPANY,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
  });
  // This is used for the active postings query in the overtime plots
  useDefaultLastMonth({
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.COMPANY,
    dateType: DefaultDates.LAST_START_DATE,
  });
  useAdaptiveRoleTaxonomy({
    viewType: ViewTypes.COMPANY,
    primaryFilters,
  });

  const dates = activeFilters.find(
    (filter) => filter.id === SelectionCategories.DATE_RANGE
  )?.value as DateRangeFormattedValues;

  const getMsa = (): string | undefined => {
    return (
      activeFilters.find(
        (filter) => filter.id === SelectionCategories.METRO_AREA
      )?.value as Array<{ id: string }>
    )?.[0]?.id as string;
  };

  const metroArea = getMsa() || '';

  const overwrites = {
    metro_area: undefined,
    ...(metroArea && { msa: [metroArea] }),
  };

  const serializedFilters: typeof overwrites & Filters = serializeFilters(
    activeFilters,
    {
      overwrites: {
        company: primaryAndCompetitorRCIDs,
        metro_area: undefined,
        ...(metroArea && { msa: [metroArea] }),
      },
      isCustomRoleTaxonomyEnabled,
    }
  );

  const [{ data: compositionData, fetching: compositionLoading }] = useQuery({
    query: COMPOSITION_GET_SUMMARY_DATA,
    variables: {
      dim1: Dimension1.Company,
      filters: serializedFilters,
    },
    pause: !entityData || !dates,
  });

  const queryFilters = useMemo(
    () =>
      transformFiltersToPostingVariables({
        view: PrimaryView.COMPANY,
        filters: [...activeFilters],
        isCustomRoleTaxonomyEnabled,
      }),
    [activeFilters, isCustomRoleTaxonomyEnabled]
  );

  const postingsFiltersWithCompetitors = {
    ...queryFilters,
    filters: {
      ...queryFilters.filters,
      provider: [9],
      metric_mode: 'expected_hires',
      company: primaryAndCompetitorRCIDs,
    },
  };

  const [{ data: postingsActiveData, fetching: postingsLoading }] = useQuery({
    query: POSTINGS_GET_ACTIVE,
    variables: postingsFiltersWithCompetitors,
    pause: !entityData,
  });

  const [{ data: sentimentData, fetching: sentimentLoading }] = useQuery({
    query: SENTIMENT_GET_COMPANY_SUMMARY_DATA,
    variables: {
      dim1: Dimension1.Company,
      filters: omit(serializedFilters, [
        // sentiment does not support seniority or skills filters
        SelectionCategories.SENIORITY,
        ...SKILL_GRANULARITY_FILTERS,
      ]),
    },
    pause: !entityData,
  });

  const {
    data: talentDiscoveryDataQuery,
    loading: talentDiscoveryDataLoading,
  } = useGetCompanyMapData();

  const [salaryDisributionLoading, setSalaryDistributionLoading] =
    useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  const allLoadingStates = useMemo(
    () => [
      compositionLoading,
      kdePlotsLoading,
      postingsLoading,
      primaryCompanyLoading,
      salaryDisributionLoading,
      sentimentLoading,
      talentDiscoveryDataLoading,
    ],
    [
      compositionLoading,
      kdePlotsLoading,
      postingsLoading,
      primaryCompanyLoading,
      salaryDisributionLoading,
      sentimentLoading,
      talentDiscoveryDataLoading,
    ]
  );

  useEffect(() => {
    if (firstLoad && allLoadingStates.some((loading) => loading)) {
      setFirstLoad(false);
    }

    if (firstLoad && allLoadingStates.every((loading) => !loading)) {
      setFirstLoad(false);
    }
  }, [firstLoad, allLoadingStates]);

  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'chartRenderedContext must be used within a ChartsRenderedProvider'
    );
  }
  const { allChartsRendered } = context;
  const anyRequestsLoading = useMemo(() => {
    const allLoadingComplete =
      allLoadingStates.every((loading) => !loading) && allChartsRendered;

    return !allLoadingComplete || firstLoad;
  }, [allLoadingStates, firstLoad, allChartsRendered]);

  const postingsStartDate = postingsFiltersWithCompetitors?.filters?.start_date;
  const postingsEndDate = postingsFiltersWithCompetitors?.filters?.end_date;

  return (
    <DashboardPage
      title={[PageTitles.COMPANY, PageTitles.SUMMARY]}
      hideSelectionsMargins
      loading={anyRequestsLoading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="companyChip"
            showColors={false}
            isPrimaryChip
            limit={PrimaryFilterLimits.COMPANY_SUMMARY}
            useChipsSkeleton={false}
            min={1}
            addButton={
              <AddEntityButton
                entities={SUMMARY_PRIMARY_VIEW_FILTER}
                entityName={AddEntityButtonText[PrimaryFilters.COMPANY]}
                buttonText={AddEntityButtonText[PrimaryFilters.COMPANY]}
                disableParentSelect
                isParentCheckboxHidden
                activeLimit={PrimaryFilterLimits.COMPANY_SUMMARY}
                limit={SHARED_SET_ENTITY_LIMIT}
                required={1}
                // trialNoResultsMessage={trialNoResultsMessage}
              />
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent={'space-between'}
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChipsLoader loading={anyRequestsLoading}>
            <FilterChips
              filterNames={SUMMARY_SECONDARY_FILTERS}
              variant="filterChip"
              limit={FilterMenuLimits.ENTITY_SUMMARY}
              showGranularity={true}
              viewType={ViewTypes.COMPANY}
              useChipsSkeleton={false}
              addButton={
                <FilterMenu
                  title="Filter"
                  filters={SUMMARY_SECONDARY_FILTERS}
                  selectMenuOpenDefault
                  limit={FilterMenuLimits.ENTITY_SUMMARY}
                  showFilterSetSaveMenu={false}
                  viewIdForDefault={`${Views.ENTITY_SUMMARY}_${ViewTypes.COMPANY}`}
                />
              }
            />
          </FilterChipsLoader>
        </Flex>
      </FilterContainer>

      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
        gridTemplateRows="1fr 1fr"
        gap={4}
        height="100%"
      >
        {/* Row 1: Three equal items */}
        <GridItem height="auto" minHeight="0" maxHeight="100%">
          <CompanySummaryOverview
            description={entityData?.entity?.summary?.description}
            founded={entityData?.entity?.summary?.founded}
            headquarters={entityData?.entity?.summary?.headquarters}
          />
        </GridItem>
        <GridItem>
          <CompetitorBarCharts
            compositionData={compositionData}
            primaryRCID={selectedCompanyRCID}
          />
        </GridItem>

        <GridItem height="auto" minHeight="0" maxHeight="100%">
          <CompanyStats
            compositionData={compositionData}
            selectedCompanyRCID={selectedCompanyRCID}
            competitors={competitors}
            postingsActiveData={postingsActiveData}
            sentimentData={sentimentData}
          />
        </GridItem>

        {/* Row 2: 2/3 and 1/3 layout */}
        <GridItem colSpan={{ base: 1, md: 2 }}>
          {postingsStartDate && postingsEndDate && (
            <CompanyMap
              mapData={
                talentDiscoveryDataQuery?.talent_discovery_search_v1_5?.map_data
              }
              postingsStartDate={postingsStartDate}
              postingsEndDate={postingsEndDate}
            />
          )}
        </GridItem>

        <GridItem colSpan={1}>
          <SalaryDistribution
            salaryData={kdeSalaryData}
            primaryCompanyRCID={selectedCompanyRCID}
            competitors={competitors}
            primaryCompanyName={entityData?.entity?.summary?.name}
            setLoading={setSalaryDistributionLoading}
          />
        </GridItem>
      </Grid>
    </DashboardPage>
  );
};

export const CompanySummaryWithChartRenderingProvider = () => (
  <ChartsRenderedProvider
    chartNames={[
      CompanyCompositionStatsMetric.Headcount,
      CompanyCompositionStatsMetric.GrowthRate,
      CompanyPostingsStatsMetric.ActivePostings,
      CompanySentimentMetric.OverallRating,
      CompanySentimentMetric.BusinessOutlookRating,
      CompanySentimentMetric.CompensationsBenefitsRating,
    ]}
  >
    <CompanySummary />
  </ChartsRenderedProvider>
);
